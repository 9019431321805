<script setup lang="ts">
import CircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import { ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { AuthStore } from "@/ContextApp/stores/auth"

const props = defineProps<{ token: string }>()

const emit = defineEmits(["return"])

const store = useStore<AuthStore>("auth", {
  shared: true,
})

const busy = ref(false)

enum State {
  initial,
  busy,
  success,
  error,
}
const state = ref(State.initial)

const handleResetPassword = async () => {
  busy.value = true
  state.value = State.busy
  await store.resetPassword?.({ token: props.token })
  if (store.data.error) {
    state.value = State.error
  } else {
    state.value = State.success
  }
}

function goBack() {
  emit('return')
  return false
}
</script>

<template>
  <div class="auth">
    <div class="auth-form">
      <div class="wrapper">
        <h1 class="title">Восстановление пароля</h1>
        <PsButton
          v-if="state === State.initial || state === State.busy"
          block
          size="lg"
          type="submit"
          color="primary"
          :disabled="state !== State.initial"
          @click="handleResetPassword"
        >
          <template v-if="state === State.initial">
            Сбросить пароль и отправить новый по почте
          </template>
          <template #prefix>
            <template v-if="state === State.busy">
              <CircularLoader />
            </template>
          </template>
          <template v-if="state === State.busy">
            Создаём новый пароль...
          </template>
        </PsButton>
        <template v-else-if="state === State.success">
          Новый пароль отправлен вам на почту.
        </template>
        <template v-else-if="state === State.error">
          Что-то пошло не так.
          <br>
          Проверьте правильность и актуальность ссылки из письма.
        </template>
      </div>
    </div>
    <div class="optional-link">
      <a href="#" @click="goBack">Вернуться</a> к форме входа в систему
    </div>
  </div>
</template>

<style scoped>
@import "../styles/auth.pcss";
</style>
