<script setup lang="ts">
import DateDelimiter from "../Date/DateDelimiter.vue"
import CircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import Error from "@/ContextTab/components/Error/Error.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsEmpty from "@/ContextTab/components/UI/PsEmpty.vue"
import { useElementVisibility } from "@vueuse/core"
import VirtualList from "@/ContextTab/components/VirtualList/VirtualList.vue"
import { toRef, useTemplateRef, watch } from "vue"
import useDateDelimiters from "./useDateDelimiters"
import type { NewsItemPublished } from "@/ContextTab/modules/news/published/components/types"


const props = withDefaults(
  defineProps<{
    items: NewsItemPublished[]
    hasDateDelimiters?: boolean
    dateDelimiterColor?: string
    initialSize?: number
    selectedItemId: number | string
    errors?: any[] | null
    hasMoreItems?: boolean
    isFetching?: boolean
  }>(),
  {
    hasDateDelimiters: true,
    dateDelimiterColor: '#eee',
    initialSize: 100,
    errors: null,
    hasMoreItems: true,
    isFetching: false,
  }
)

const emit = defineEmits<{
  (e: 'reachedStart', payload: boolean): void,
  (e: 'reachedEnd', payload: boolean): void,
}>()

const { currentDate, needDateDelimiter } = useDateDelimiters(
  toRef(props, "items")
)

const list = useTemplateRef<InstanceType<typeof VirtualList>>('list')

const beforeLoadingTrigger = useTemplateRef<HTMLDivElement>('beforeLoadingTrigger')
const isBeforeVisible = useElementVisibility(beforeLoadingTrigger)

watch(isBeforeVisible, (next, prev) => {
  if (next && !prev) {
    emit("reachedStart", next)
  }
})

function scrollToTop() {
  return list.value?.scrollToOffset(0)
}

function scrollToIndex(index: number) {
  return list.value?.scrollToIndex(index, { align: 'center' })
}

function getScrollTopSize(): number | null | undefined {
  return list.value?.scrollOffset
}

defineExpose({
  scrollToTop,
  scrollToIndex,
  getScrollTopSize,
})
</script>

<template>
  <div class="panel-list">
    <slot name="filter" />

    <template v-if="errors?.length">
      <Error :errors="errors">
        <template #request-error>
          <slot name="request-error" />
        </template>
      </Error>
    </template>

    <template v-else-if="!items?.length">
      <div
        v-if="isFetching"
        class="loader-wrapper is-empty"
      >
        <CircularLoader />
      </div>
      <div
        v-else
        class="empty-text"
      >
        <PsEmpty title="Ничего не найдено" />
        <slot name="empty-actions" />
      </div>
    </template>

    <VirtualList
      v-else
      ref="list"
      :count="items?.length"
      :estimateSize="initialSize"
      :hasMoreItems="hasMoreItems"
      @loadMore="emit('reachedEnd', true)"
    >
      <template #before>
        <div ref="beforeLoadingTrigger">
          <slot name="before" />
        </div>
      </template>

      <template #item="{ index }">
        <DateDelimiter
          v-if="hasDateDelimiters && needDateDelimiter(index)"
          :key="currentDate"
          :date="items[index].status_modified_at"
          :color="dateDelimiterColor"
        />
        <slot :index="index" />
      </template>

      <template
        v-if="items?.length > 0 && hasMoreItems"
        #after
      >
        <div class="loader-wrapper">
          <CircularLoader v-if="isFetching" />
          <PsButton
            v-else
            round
            @click="emit('reachedEnd', true)"
          >
            Загрузить ещё
          </PsButton>
        </div>
      </template>
    </VirtualList>
  </div>
</template>

<style scoped>
.panel-list {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  &.is-empty {
    height: 100%;
  }
}

.empty-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
</style>
