import { useThrottleFn } from "@vueuse/core"
import { ref, computed, watch } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { SetType } from "@/lib/types"
import type { NewsSetStore } from "@/ContextApp/stores/news/task-panel/composables/useSet.ts"

export default function useTaskPanelList(setType: SetType) {
  const store = useStore<NewsSetStore>(`${setType}News`, {
    onStart: store => store.loadNext(),
  })
  const items = computed(() => store.data.newsList?.filter(item => !!item) ?? [])
  const hasMoreItems = computed(() => store.data.hasMoreItems ?? true)
  const isFetching = computed(() => store.data.isFetching ?? true)
  const initialLoaded = computed(
    () =>
      !isFetching.value && (items.value.length || !hasMoreItems.value)
  )

  const errors = computed(() => {
    return store.data.error ? [store.data.error] : []
  })

  const selectedId = ref(1)

  const selectItem = (item: any) => {
    if (item?.id != null) {
      selectedId.value = item.id
    }
  }

  const getIsNewsItemInSet = (id: string | number) => {
    return store.data.set?.includes(id)
  }

  const onReachedEnd = useThrottleFn(
    (visible: boolean) => {
      if (visible === false) return
      if (
        items.value?.length &&
        !store.data.error &&
        !isFetching.value &&
        store.data.hasMoreItems
      ) {
        store.loadNext?.()
      }
    },
    500,
    true
  )

  return {
    items,
    reload: () => store.reload?.(),
    selectedId,
    selectItem,
    onReachedEnd,
    hasMoreItems,
    errors,
    isFetching,
    initialLoaded,
    getIsNewsItemInSet,
  }
}
