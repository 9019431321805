import decode from "jwt-decode"
import type { PsApiV1 } from "ps-client"
import { ApiError } from "ps-client"
import { ref, computed, nextTick } from "vue"
import { apiService } from "@/ContextApp/services/api"
import { appMetrics } from "@/ContextApp/services/appMetrics"
import storage from "@/ContextApp/services/storage"
import legacyBridge from "@/ContextApp/services/legacyBridge"
import { v1 as uuidv1 } from 'uuid'


interface TokenData {
  fresh: boolean
  iat: number
  jti: string
  type: "access" | "refresh"
  sub: number
  nbf: number
  exp: number
  permissions: string[]
}

export enum AuthStatus {
  initial = 0,
  notAuthorized = 1,
  authorized = 2,
}

function generateSessionId() {
  return uuidv1().replace(/-/g, "")
}

function define() {

  const sessionId = ref<string>(generateSessionId())

  const accessToken = ref<string | null>(null)
  const refreshToken = ref<string | null>(null)
  const login = ref<string | null>(null)

  const tokenData = computed<TokenData | null>(() =>
    accessToken.value ? decode(accessToken.value) : null
  )

  const authorized = ref<AuthStatus>(AuthStatus.initial)
  const error = ref<{ errors: { code: string, title: string }[] } | null>(null)

  const userId = computed(() => tokenData.value?.sub ? Number(tokenData.value?.sub) : null)

  function regenerateSessionId() {
    sessionId.value = generateSessionId()
  }

  async function loadFromStorage() {
    const storedRefreshToken = await storage.getItem("refreshToken")
    if (storedRefreshToken?.data) {
      refreshToken.value = storedRefreshToken.data
    }
    const storedAccessToken = await storage.getItem("accessToken")
    if (storedAccessToken?.data) {
      accessToken.value = storedAccessToken.data
      appMetrics.setUser({
        id: tokenData.value?.sub,
      })
      const storedLogin = await storage.getItem("login")
      if (storedLogin?.data) {
        login.value = storedLogin?.data
        appMetrics.setUser({
          email: storedLogin?.data,
        })
      }
      authorized.value = AuthStatus.authorized
    } else {
      authorized.value = AuthStatus.notAuthorized
    }
  }

  async function logout() {
    storage.clear()
    accessToken.value = null
    refreshToken.value = null
    login.value = null
    authorized.value = AuthStatus.notAuthorized
    await nextTick()
    regenerateSessionId()
  }

  async function updateToken(access: string, refresh: string) {
    if (!access && !refresh) {
      return {}
    }
    accessToken.value = access
    refreshToken.value = refresh
    await storage.setItem("accessToken", access)
    await storage.setItem("refreshToken", refresh)
    legacyBridge.updateToken(access, refresh)
    appMetrics.setUser({
      id: tokenData.value?.sub,
    })
    return { access, refresh }
  }

  async function authorize({ username, password }: PsApiV1.AuthPostIn) {
    error.value = null
    const response = await apiService.api.auth.login({ username, password })

    if (response instanceof ApiError || !response) {
      authorized.value = AuthStatus.notAuthorized

      error.value = response
        ? JSON.parse(JSON.stringify(response)) // ApiError extends Error... ref: PUB-4154
        : { errors: [{ title: 'Нет ответа от сервера', code: uuidv1() }] }

      if (!response) {
        appMetrics.sendException("No response from api.auth.login", { username })
      }

      return response
    }

    await updateToken(response.access_token, response.refresh_token)

    appMetrics.setUser({
      id: tokenData.value?.sub,
      email: username,
    })

    login.value = username
    storage.setItem("login", username)
    authorized.value = AuthStatus.authorized
    return response
  }

  async function requestPasswordReset({ username }: PsApiV1.LostPasswordPostIn) {
    error.value = null
    const response = await apiService.api.auth.requestPasswordReset({ username })
    if (response instanceof ApiError) {
      error.value = response
    }
    return response
  }

  async function resetPassword(token: PsApiV1.ResetPasswordPostIn) {
    error.value = null
    const response = await apiService.api.auth.resetPassword(token)
    if (response instanceof ApiError) {
      error.value = response
    }
    return response
  }

  function setNotAuthorized(errorMessage: string = '') {
    authorized.value = AuthStatus.notAuthorized

    if (errorMessage) {
      error.value = error.value ?? { errors: [] }
      error.value?.errors?.push({
        title: errorMessage,
        code: uuidv1(),
      })

      console.log('@alex21 add blocked url error', error.value)
    }
  }

  return {
    sessionId,
    accessToken,
    authorized,
    refreshToken,
    tokenData,
    error,
    login,
    userId,
    regenerateSessionId,
    authorize,
    setNotAuthorized,
    loadFromStorage,
    logout,
    updateToken,
    requestPasswordReset,
    resetPassword
  }
}

export type AuthStore = ReturnType<typeof define>

export default {
  define,
}
