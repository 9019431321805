type PsMessageMetaTypeId =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 999
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "999"
export type PsMessageMetaSource = "client" | "server"

export type PsMessageMetaType = {
  id: PsMessageMetaTypeId
  name: string
  label: string
}
type PsMessageMetaTypes = {
  [key in PsMessageMetaTypeId]: PsMessageMetaType
}

type PsCommandMessageStatusId = 0 | 1 | "0" | "1"
type PsCommandMessageStatusName = "success" | "error"

type PsCommandMessageStatus = {
  id: PsCommandMessageStatusId
  name: PsCommandMessageStatusName
}
type PsCommandMessageStatuses = {
  [key in PsCommandMessageStatusId]: PsCommandMessageStatus
}

type PsMessageMetaIdField = PsMessageMetaTypeId | PsCommandMessageStatusId

type PsMessageMetaField = {
  [key: string | number]: any
}

// Класс для сравенения метаданных.
// TODO: теперь metaFilter всегда функция, так что, возможно, больше не нужен
class MessageFieldMetaProvider<MetaT extends PsMessageMetaField, MetaPayloadT> {
  private _data: MetaT
  public names: { [key: string]: string }
  constructor(data: MetaT) {
    this._data = data

    this.names = Object.values(data).reduce((acc, value) => {
      acc[value.name] = value.name
      return acc
    }, {})

    this.isOk = this.isOk.bind(this)
  }

  byId(id: PsMessageMetaIdField): MetaPayloadT {
    return this._data[id]
  }

  byName(name: string): MetaPayloadT {
    const id = Object.keys(this._data).find(
      key => this._data[key as PsMessageMetaIdField]?.name === name
    ) ?? 3 // WTF? ))
    return this._data[id]
  }

  // isOk(id: PsMessageMetaIdField) {
  isOk(commandResult: PsHubCommandResult) {
    return this.byId(commandResult?.meta?.id)?.name === this.names.success
  }
}

export const messageTypes = new MessageFieldMetaProvider<
  PsMessageMetaTypes,
  PsMessageMetaType
>({
  "1": {
    id: 1,
    name: "data",
    label: "Обновление данных",
  },
  "2": {
    id: 2,
    name: "notification",
    label: "Оповещение",
  },
  "3": {
    id: 3,
    name: "service",
    label: "Техническое сообщение",
  },
  "4": {
    id: 4,
    name: "command",
    label: "Действие",
  },
  "5": {
    id: 5,
    name: "commandResult",
    label: "Результат действия",
  },

  "999": {
    id: 999,
    name: "ping",
    label: "Проверка готов ли AppWorker к обработке сообщений",
  },
})

export const commandTypes = {
  fetch: "fetch",
  subscribe: "subscribe",
  unsubscribe: "unsubscribe",
  getState: "getState",
  view: "view",
  store: "store",
} as const

export const commandStatuses = new MessageFieldMetaProvider<
  PsCommandMessageStatuses,
  PsCommandMessageStatus
>({
  "0": {
    id: 0,
    name: "success",
  },
  "1": {
    id: 1,
    name: "error",
  },
})

export type PsHubMessageMetaPartial<MetaT = any> = MetaT & {
  typeId?: PsMessageMetaTypeId
  [key: string]: any
}
export type PsHubMessageMeta<MetaT = any> = PsHubMessageMetaPartial &
  MetaT & {
    contextId: string
    typeId: PsMessageMetaTypeId
    source: PsMessageMetaSource
    messageId: string
  }

export type PsHubCommandType = (keyof typeof commandTypes)

export type PsHubCommandMetaPartial<MetaT = any> = MetaT & {
  commandType: PsHubCommandType
}

export type PsHubCommandMeta<MetaT = any> = PsHubMessageMeta &
  PsHubCommandMetaPartial &
  MetaT & {
    commandId: string
  }

export type PsHubCommandResultMeta = PsHubCommandMeta & {
  statusId: PsCommandMessageStatusId
}

export type PsHubCommandFetchMeta = PsHubMessageMetaPartial<{
  method: string
}>

// Message
export type PsHubMessage<MetaT> = {
  meta: PsHubMessageMeta<MetaT>
  payload: PsHubMessagePayload
}

export type PsHubMessagePayload = any

// Command
export type PsHubCommand = PsHubMessage<PsHubCommandMeta>

export type PsHubCommandResult = PsHubCommand & {
  meta: PsHubCommandResultMeta
}

// Fetch command
export type PsHubCommandFetch = PsHubCommand & {
  meta: PsHubCommandFetchMeta
}

export type PsHubFetchMethod = "GET" | "POST" | "PUT" | "DELETE" | "HEAD"

export const fetchCommandMethods: { [key: string]: PsHubFetchMethod } = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  head: "HEAD",
}
