<script setup lang="ts">
import ShareSettings from "./ShareSettings.vue"
import PsSearchField from "@/ContextTab/components/SearchField/PsSearchField.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsModal from "@/ContextTab/components/UI/PsModal.vue"
import PsTag from "@/ContextTab/components/UI/PsTag.vue"
import WithInplaceMessage from "@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue"
import PsUsersList from "@/ContextTab/components/Users/PsUsersList.vue"
import { computed, ref, onMounted, type Ref, useTemplateRef } from "vue"
import useShareModal from "@/ContextTab/modules/news/composables/useShareModal"
import useProfile from "@/ContextTab/modules/user/composables/useProfile"
import userAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"
import { useStore } from "@/ContextTab/useStore"
import { formatUsername } from "@/utils/formatUsername"
import { searchQueryToRegExps } from "@/utils/searchQueryToRegExps"
import type { GroupsAndUsersStore } from "@/ContextApp/stores/groupsAndUsers"
import type { Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"

defineEmits<{
  (e: "message", val: Message): void
}>()

const show = defineModel<boolean>({ default: false })

const { userId } = userAuthInfo()
const storeGroupAndUsers = useStore<GroupsAndUsersStore>("groupsAndUsers", {
  shared: true,
})

storeGroupAndUsers.ready.then(() => {
  storeGroupAndUsers.fetchUserStatuses?.()
  !asoiMode.value && defaultRecipient.value && updateSelected([defaultRecipient.value])
})

const showSettings = ref(false)
const searchField = useTemplateRef('searchField')
const usersList = useTemplateRef('usersList')

const title = computed(() =>
  asoiMode.value ? "Отправить новость в АСОИ" : "Отправить новость"
)

onMounted(() => {
  searchField.value?.inputFocus()
})

const { modalState, shareDispatcher } = useShareModal()

const asoiMode = computed(() => modalState.value.actionType === "sendToAsoi")

const COMMENT_MAX_LENGTH = 1000
const ASOI_COMMENT_MAX_LENGTH = 450

const SELECTED_INIT = () => []
const SUBSCRIBED_INIT = () => []
const COMMENT_INIT = () => ""
const SEARCH_QUERY_INIT = () => ""
const COMMENT_HEIGHT_INIT = () => "30px"
const KEEP_ORIGINAL_INIT = () => false

const selected = ref(SELECTED_INIT())
const subscribed = ref(SUBSCRIBED_INIT())
const comment = ref(COMMENT_INIT())
const searchQuery = ref(SEARCH_QUERY_INIT())
const commentHeight = ref(COMMENT_HEIGHT_INIT())
const keepOriginal = ref(KEEP_ORIGINAL_INIT())

const loading = ref(false)
const message: Ref<Message | null> = ref(null)

const maxCommentLength = computed(() =>
  asoiMode.value ? ASOI_COMMENT_MAX_LENGTH : COMMENT_MAX_LENGTH
)

const commentLengthExceeded = computed(
  () => comment.value.length > maxCommentLength.value
)

const withNotifications = computed(() => !asoiMode.value)

const { updateDefaultRecipient, defaultRecipientId } = useProfile({})

const defaultRecipient = computed(() => {
  if (asoiMode.value || !defaultRecipientId.value) return null
  return (
    storeGroupAndUsers.data.recipients?.find(
      r => r.id === defaultRecipientId.value
    ) ?? null
  )
})

const shareModalRecipients = computed(() => {
  if (defaultRecipient.value) {
    return [
      defaultRecipient.value,
      ...(storeGroupAndUsers.data.recipients ?? []).filter(
        r => r.id !== defaultRecipientId.value
      ),
    ]
  }
  return storeGroupAndUsers.data.recipients ?? []
})

const selectedRecipients = computed(() =>
  selected.value.filter(r => r.id !== defaultRecipientId.value)
)

function updateSelected(selectedRecipients: any) {
  selected.value = selectedRecipients

  if (withNotifications.value) {
    if (
      selectedRecipients.length &&
      !selectedRecipients.some(r => isSubscribed(r.id))
    ) {
      subscribe(selected.value[0].id)
    }
    const removedSubscribedIds = subscribed.value.filter(
      id => !selected.value.find(r => id === r.id)
    )
    removedSubscribedIds.forEach(id => unsubscribe(id))
  }
}

function removeFromSelected(item) {
  selected.value = selected.value.filter(r => item.id !== r.id)
  unsubscribe(item.id)
}

function handleSearch(query: string) {
  searchQuery.value = query || ""
}

function subscribe(id) {
  !isSubscribed(id) && subscribed.value.push(id)
}

function unsubscribe(id) {
  subscribed.value = subscribed.value.filter(iId => iId !== id)
}

function toggleSubscription(e, id) {
  e.stopPropagation()
  isSubscribed(id) ? unsubscribe(id) : subscribe(id)
}

const excludeCurrentUser = ({ isUser, id }: { isUser: boolean, id: number }) => {
  return !(isUser && id == userId.value)
}

const filteredRecipients = computed(() => {
  const regExps = searchQueryToRegExps(searchQuery.value)
  const result = !searchQuery.value ? shareModalRecipients.value : shareModalRecipients.value.filter(item => {
    return regExps.every(
      regex =>
        regex.test(formatUsername(item.name || "")) ||
        regex.test(item.first_name || "") ||
        regex.test(item.last_name || "") ||
        regex.test(item.patronymic || "") ||
        regex.test(item.code || "")
    )
  });

  return asoiMode.value ? result : result.filter(excludeCurrentUser)
})

function isSelected(id) {
  return !!selected.value.find(i => i.id === id)
}

function isSubscribed(id) {
  return subscribed.value.includes(id)
}

async function share() {
  loading.value = true
  message.value = null
  const payload: any = asoiMode.value
    ? {
        group_ids: selected.value
          .filter(({ is_group }) => is_group)
          .map(item => item.id),
        user_ids: selected.value
          .filter(({ is_group }) => !is_group)
          .map(item => item.id),
        comment: comment.value.trim(),
      }
    : {
        recipients: selected.value.map(item => ({
          type: item.is_group ? "group" : "user",
          id: item.id,
          subscribe_to_notifications: isSubscribed(item.id),
        })),
        keep_original: keepOriginal.value,
        comment: comment.value.trim(),
      }
  await shareDispatcher(payload)
  loading.value = false
  show.value = false
}

function updateCommentHeight(e) {
  commentHeight.value = Math.min(e.target.scrollHeight, 186) + "px"
  if (comment.value.length === 0) {
    commentHeight.value = COMMENT_HEIGHT_INIT()
  }
}

async function setDefault({
  defaultRecipientGroupId,
  defaultRecipientUserId,
}: {
  defaultRecipientGroupId: string | number | null
  defaultRecipientUserId: string | number | null
}) {
  loading.value = true
  await updateDefaultRecipient({
    defaultRecipientGroupId,
    defaultRecipientUserId,
  })
  const messageText =
    defaultRecipientGroupId || defaultRecipientUserId
      ? "Получатель по умолчанию успешно сохранен"
      : "Получатель по умолчанию удален"
  message.value = {
    type: "success",
    text: messageText,
  }
  loading.value = false
}
</script>

<template>
  <PsModal
    v-model:show="show"
    size="sm"
    class="share-modal"
    :bodyPadding="false"
    :footerPadding="false"
  >
    <template #header>
      {{ title }}
      <ShareSettings
        v-model:show="showSettings"
        class="share-modal__settings"
        :defaultRecipient="defaultRecipient"
        :selectedRecipients="selectedRecipients"
        @submit="setDefault"
      >
        <PsTooltip
          v-if="withNotifications"
          class="share-modal__settings-btn__tooltip"
          content="Настройки"
        >
          <PsButton
            class="share-modal__settings-btn"
            color="primary"
            size="sm"
            :padding="false"
            @click="showSettings = true"
          >
            <PsIcon type="font" name="settings" :size="16" />
          </PsButton>
        </PsTooltip>
      </ShareSettings>
    </template>

    <div class="share-modal__content">
      <div class="share-modal__options">
        <PsCheckbox
          v-if="!asoiMode"
          v-model:checked="keepOriginal"
          class="share-modal__options-checkbox"
          label="Продолжить работать с новостью"
        />
        <div class="search">
          <PsSearchField
            ref="searchField"
            :initialQuery="searchQuery"
            placeholder="Поиск по имени"
            color="primary-light"
            @input="handleSearch"
          >
            <template #prefix>
              <PsIcon type="font" name="search" />
            </template>
          </PsSearchField>
        </div>
        <div class="selected-items">
          <PsTag
            v-for="(item, index) in selected"
            :id="item.id"
            :key="index + '-' + item.id"
            color="secondary"
            size="md"
            :closable="true"
            @close="() => removeFromSelected(item)"
          >
            {{ item.name }}
            <PsTooltip
              v-if="withNotifications"
              :content="
                isSubscribed(item.id)
                  ? 'Удалить подписку'
                  : 'Активировать подписку'
              "
            >
              <PsIcon
                :name="
                  isSubscribed(item.id)
                    ? 'notification'
                    : 'notification-outlined'
                "
                class="item__notification"
                :size="14"
                :color="isSubscribed(item.id) ? '#0069e4' : '#000000'"
                @click="e => toggleSubscription(e, item.id)"
              />
            </PsTooltip>
          </PsTag>
        </div>

        <div class="comment">
          <div class="comment__input-wrapper">
            <span v-if="commentLengthExceeded" class="comment__error">
              Превышен лимит в {{ maxCommentLength }} символов
            </span>
            <textarea
              v-model="comment"
              class="comment__input"
              :class="{ '-error': commentLengthExceeded }"
              :style="{ height: commentHeight }"
              placeholder="Комментарий"
              @input="updateCommentHeight"
            />
          </div>
        </div>
      </div>

      <div class="share-modal__list">
        <WithInplaceMessage :loading="loading" :message="message">
          <PsUsersList
            ref="usersList"
            :entries="filteredRecipients"
            :checkedItems="selected"
            :searchQuery="searchQuery"
            :multipleMode="true"
            :useLayoutTranslation="true"
            @selected="updateSelected($event)"
          >
            <template v-if="withNotifications" #optional-data="{ entry: item }">
              <div
                class="item-list-entry__notification"
                :class="{ '-extra-margin': item.is_group }"
              >
                <PsTooltip
                  v-if="isSelected(item.id)"
                  :content="
                    isSubscribed(item.id)
                      ? 'Удалить подписку'
                      : 'Активировать подписку'
                  "
                >
                  <PsIcon
                    :name="
                      isSubscribed(item.id)
                        ? 'notification'
                        : 'notification-outlined'
                    "
                    :size="18"
                    :color="isSubscribed(item.id) ? '#0069e4' : '#000000'"
                    @click="e => toggleSubscription(e, item.id)"
                  />
                </PsTooltip>
              </div>
            </template>
          </PsUsersList>
        </WithInplaceMessage>
      </div>
    </div>

    <template #footer>
      <PsButton
        color="primary"
        :block="true"
        :disabled="!selected.length || commentLengthExceeded"
        @click="share"
      >
        Отправить выбранным

        <template #suffix>
          <PsIcon name="button-icon-send" :size="18" />
        </template>
      </PsButton>
    </template>
  </PsModal>
</template>

<style lang="postcss" scoped>
.modal-wrapper {
  --modal--font-size: 1.5rem;
}

.share-modal__content {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.share-modal__options {
  padding: 0 var(--modal--padding-left);
  background-color: var(--color-primary);
}

.share-modal__options-checkbox {
  --checkbox--checked--background: var(--color-white);
  --checkbox--color: var(--color-primary);
  --checkbox--label--color: var(--color-white);
  --font-size: 0.9rem;
}

.selected-items {
  overflow: auto;
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  flex-shrink: 0;
  max-height: 95px;

  &::-webkit-scrollbar {
    background-color: var(--color-primary);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-white);
  }
}

.share-modal__list {
  flex-grow: 1;
  overflow-y: auto;
}

.pskit__popover {
  margin-left: auto;
  align-self: center;
}

.share-modal__settings-btn {
  height: 1.5rem;
  width: 1.5rem;
  border: 0;
  margin-bottom: 1px;
  margin-right: 3px;
}

.search {
  padding: 0.5rem 0;
  z-index: 1;
}

.comment__input-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--color-white);
  box-shadow: none;
}

.comment {
  display: flex;
  color: var(--color-white);
  align-items: center;
  padding: 0.5rem 0 var(--modal--padding-top) 0;
}

.comment__input {
  flex-grow: 1;
  margin: 0;
  padding: 7px;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 3px;
  resize: none;
  line-height: 1;

  &.-error {
    color: var(--color-danger);
  }
}

.comment__error {
  align-self: center;
  display: inline-block;
  background-color: var(--color-danger);
  margin-bottom: 7px;
  border-radius: 3px;
  font-size: 14px;
  padding: 0 5px;
}

.item__notification {
  margin-left: 3px;
  margin-bottom: 1px;
  cursor: pointer;
}

.item-list-entry__notification {
  &.-extra-margin {
    margin-right: calc(42px + 1rem);
  }
}
</style>

<style lang="postcss">
.share-modal {
  .modal-header {
    align-items: center;
  }
}
</style>
