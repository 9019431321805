import { computed, ref, type Ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import { makeProfilePatch } from "@/lib/profileHelpers"
import type { SetProfileColorData } from "@/ContextTab/modules/colors/types"
import type { UserProfileStore } from "@/ContextApp/stores/userProfile"

export const enum PanelMode {
  horizontal = 1,
  vertical = 2,
}

type ColorIndicatorType = "codes" | "authors"

export default function ({
  colorIndicationType = ref("codes"),
}: {
  colorIndicationType?: Ref<ColorIndicatorType>
}) {
  const store = useStore<UserProfileStore>("userProfile", {
    shared: true,
  })

  const profile = computed(() => store.data.profile)

  const name = computed(() => profile.value?.name)
  const code = computed(() => profile.value?.code)
  const userGroups = computed(() =>
    profile.value?.groups?.map(group => group.group)
  )
  const keepOriginalNews = computed(
    () => profile.value?.keepOriginalNews ?? false
  )

  const publishedPanelMode = computed(() => {
    const mode = profile.value?.publishedPanelMode ?? 1
    return mode > 1 ? 2 : 1
  })

  const getColorsByType = computed(() => {
    const colors = profile.value?.colors?.[colorIndicationType.value] || []
    return colors.reduce((acc, curr) => {
      acc[curr.id] = {
        color: `#${curr.color}`,
        includeChildren: curr.includeChildren,
      }
      return acc
    }, {})
  })

  const defaultRecipientId = computed(() => {
    return (
      profile.value?.default_recipient_group_id ||
      profile.value?.default_recipient_user_id
    )
  })

  const updateDefaultRecipient = async ({
    defaultRecipientGroupId,
    defaultRecipientUserId,
  }: {
    defaultRecipientGroupId: string | number | null
    defaultRecipientUserId: string | number | null
  }) => {
    const updated = makeProfilePatch(profile)
    updated.default_recipient_group_id = defaultRecipientGroupId ?? null
    updated.default_recipient_user_id = defaultRecipientUserId ?? null
    await store.update?.(updated)
  }

  const updatePublishedPanelMode = (panelMode: PanelMode) => {
    const updated = makeProfilePatch(profile)
    updated.publishedPanelMode = panelMode
    store.update?.(updated)
  }

  const updateFilters = (filterName, value) => {
    const updated = makeProfilePatch(profile)
    updated.filters[filterName] = value
    store.patch?.(updated)
  }

  const updateColors = async (items: SetProfileColorData) => {
    const updated = makeProfilePatch(profile)

    if (!updated.colors) {
      updated.colors = {}
    }

    items.forEach(item => {
      if (!updated.colors[item.type]) {
        updated.colors[item.type] = []
      }

      // если собираемся сохранить или удалить цвет для кода, для которого уже был назначен цвет, то сначала отфильтровываем его 
      // из обновляемых данных, чтобы потом сохранить с новыми данными или сохранить без него
      updated.colors[item.type] = updated.colors[item.type].filter(
        color => color.id !== item.id
      )

      if (item.color === null) {
        return
      }

      updated.colors = {
        ...updated.colors,
        [item.type]: [
          ...updated.colors[item.type],
          {
            id: item.id,
            color: item.color,
            includeChildren: item.includeChildren,
          },
        ],
      }
    })

    return store.update?.(updated)
  }

  return {
    profile,
    publishedPanelMode,
    error: store.data.error || null,
    getColorsByType,
    defaultRecipientId,

    name,
    code,
    userGroups,
    keepOriginalNews,

    updatePublishedPanelMode,
    updateFilters,
    updateColors,
    updateDefaultRecipient,
  }
}
