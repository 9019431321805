<script setup lang="ts">
import PsSubmitGroup from "@/ContextTab/components/PsSubmitGroup.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsPopover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { ICard } from "@inkline/inkline"
import { useVModel } from "@vueuse/core"
import { ref, watch } from "vue"
import { codeColors } from "@/config/colors"

const props = withDefaults(
  defineProps<{
    show?: boolean | undefined
    appliedColor?: { color?: string; includeChildren?: boolean }
    codeHasChildren?: boolean
  }>(),
  {
    show: undefined,
    appliedColor: () => ({}),
    codeHasChildren: false,
  }
)

const emit = defineEmits<{
  (e: "update:show"): void,
  (e: "submit", payload: typeof props.appliedColor): void,
}>()

const curShow = useVModel(props, "show", emit)
const pickedColor = ref(props.appliedColor?.color || null)
const includeChildren = ref(props.appliedColor?.includeChildren || false)

const pickColor = (color) => {
  pickedColor.value = pickedColor.value === color ? null : color
}

watch(curShow, next => onUpdateShow(next))

const submit = () => {
  emit("submit", { color: pickedColor.value, includeChildren: includeChildren.value })
  close()
}

const onUpdateShow = (show: boolean) => {
  if (show) {
    pickedColor.value = props.appliedColor?.color || null
    includeChildren.value = props.appliedColor?.includeChildren || false
  }
}

const clear = () => {
  pickedColor.value = null
}

const close = () => {
  curShow.value = false
}
</script>

<template>
  <PsPopover
    trigger="manual"
    :open="curShow"
    :hideOnEscape="true"
    placement="top"
    class="color-picker"
    @clickOutside="close"
    @hide="close"
  >
    <slot name="trigger" />
    <template #body>
      <ICard class="color-picker-card">
        <template #header>
          <slot name="header">
            <div
              class="_display:flex _justify-content:space-between _align-items:center"
            >
              <h5 class="color-picker__title">Выбор цвета</h5>
              <PsButton :circle="true" size="sm" color="light" @click="close">
                <PsIcon type="font" name="close" size="14" />
              </PsButton>
            </div>
          </slot>
        </template>
        <div class="color-picker__palette">
          <div
            v-for="color in codeColors"
            :key="color"
            :style="{ backgroundColor: color }"
            class="color-picker__palette-item"
            :class="{ active: color === pickedColor }"
            @click="pickColor(color)"
          >
            <Transition name="fade">
              <PsIcon
                v-show="color === pickedColor"
                name="check-icon"
                class="color-picker__check-icon"
                color="white"
                :size="12"
              />
            </Transition>
          </div>
        </div>
        <PsCheckbox
          v-if="codeHasChildren"
          v-model:checked="includeChildren"
          label="Включая дочерние коды"
          class="color-picker__checkbox"
        />
        <template #footer>
          <slot name="footer">
            <div
              class="_display:flex _justify-content:space-between _align-items:center"
            >
              <PsSubmitGroup @submit="submit" @cancel="curShow = false" />
              <PsButton :round="true" size="sm" color="light" @click="clear">
                Без цвета
              </PsButton>
            </div>
          </slot>
        </template>
      </ICard>
    </template>
  </PsPopover>
</template>

<style lang="postcss">
.color-picker {
  --popover--padding: 0;
}
.color-picker-card {
  --card--box-shadow: none;
  --card--header--border-width: 0;
  --card--body--border-right-width: 0;
  --card--body--border-left-width: 0;
  --card--footer--border-width: 0;
}
</style>

<style lang="postcss" scoped>
.color-picker__title {
  font-weight: 700;
  font-size: 1.1em;
}
.color-picker__palette {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 12px;
}
.color-picker__palette-item {
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-radius: 2px;
  transition: border-color 0.1s ease-out;
  cursor: pointer;
  z-index: 0;

  &.active,
  &:hover {
    border-color: #0069e4;
  }
}
.color-picker__check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.color-picker__checkbox {
  margin-top: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
