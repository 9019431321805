<script setup lang="ts">
import CodeColorIndicator from "./CodeColorIndicator.vue"
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    codeIds?: []
    allCodeItems?: object
    allCodeItemsByUid?: object
    codeColors?: object
    codeType?: "pubs" | "non-pubs"
    newsId?: number | string | undefined
    codesFilter?: (code: any) => boolean
    isCompact?: boolean
    feedMaxItems?: number
    nonFeedMaxItems?: number
  }>(),
  {
    codeIds: () => [],
    allCodeItems: () => ({}),
    allCodeItemsByUid: () => ({}),
    codeColors: () => ({}),
    codeType: "pubs",
    newsId: undefined,
    codesFilter: (code?: any) => true,
    isCompact: false,
    feedMaxItems: 4,
    nonFeedMaxItems: 3,
  }
)
const feedsCodes = computed(() =>
  props.codeIds.filter(id => props.codesFilter({ id }))
)
const nonFeedsCodes = computed(() =>
  props.codeIds.filter(id => !props.codesFilter({ id }))
)
</script>

<template>
  <CodeColorIndicator
    v-if="feedsCodes.length || isCompact"
    :codeIds="feedsCodes"
    :allCodeItems="allCodeItems"
    :allCodeItemsByUid="allCodeItemsByUid"
    :codeColors="codeColors"
    :maxItems="feedMaxItems"
    :isCompact="isCompact"
    codeType="feeds"
    data-test-selector="code-indicator-line"
  />
  <CodeColorIndicator
    v-if="nonFeedsCodes.length || isCompact"
    :codeIds="nonFeedsCodes"
    :allCodeItems="allCodeItems"
    :allCodeItemsByUid="allCodeItemsByUid"
    :codeColors="codeColors"
    :maxItems="nonFeedMaxItems"
    :isCompact="isCompact"
    codeType="non-feeds"
    data-test-selector="code-indicator-circle"
  />
</template>

<style lang="postcss" scoped></style>
