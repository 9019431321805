<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"

type ActiveButton = "submit" | "cancel" | undefined

interface Props {
  submitDisabled?: boolean
  submitLabel?: string
  cancelLabel?: string
  buttonWidth?: string // для случая, когда нужны обе кнопки одинаковой ширины
  activeButton?: ActiveButton
}

withDefaults(defineProps<Props>(), {
  submitDisabled: false,
  submitLabel: "Применить",
  cancelLabel: "Отмена",
  buttonWidth: "auto",
  activeButton: undefined,
})

const emit = defineEmits(["submit", "cancel"])
</script>

<template>
  <div class="ps-submit-group">
    <PsButton
      :disabled="submitDisabled"
      :active="activeButton === 'submit'"
      round
      data-test-selector="submit-button"
      @click="emit('submit')"
    >
      {{ submitLabel }}
    </PsButton>
    <PsButton
      :active="activeButton === 'cancel'"
      round
      data-test-selector="cancel-button"
      @click="emit('cancel')"
    >
      {{ cancelLabel }}
    </PsButton>
  </div>
</template>

<style scoped lang="postcss">
.ps-submit-group {
  display: flex;
  gap: 0.75em;

  .button:not(:disabled, .-disabled):active,
  .button:not(:disabled, .-disabled).-active {
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
}

.ps-button {
  width: v-bind(buttonWidth);
}
</style>
