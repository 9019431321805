import type { PsApiV2 } from 'ps-client'

interface ReferenceValue {
  description: string
  name: string
}

// TODO: Заменить все самописные типы на сгенерированные из ps-client (комментарий насчет этих 3 типов лежит в задаче: https://jira.interfax.ru/browse/PUB-3866)
type EntityPropertyType = 1 | 2 | 3
type FlowEventType = 1
type NewsFTSTargetType = 1 | 2 | 3

export const REFERENCES = Object.freeze(
  {
    "ASOINoteEvent": {
      "Type": {
        "1": {
          "description": "Создана",
          "name": "CREATED",
        },
        "2": {
          "description": "Прочитана",
          "name": "READ",
        },
        "3": {
          "description": "Взята в работу",
          "name": "PICKED",
        },
        "4": {
          "description": "Удалена",
          "name": "DELETED",
        },
        "5": {
          "description": "Прочитана в АСОИ",
          "name": "READ_IN_ASOI",
        },
        "6": {
          "description": "Удалена в АСОИ",
          "name": "DELETED_IN_ASOI",
        },
        "7": {
          "description": "Изменены коды",
          "name": "CODES_CHANGED",
        },
        "8": {
          "description": "Изменено время публикации",
          "name": "PUBLICATION_TIME_CHANGED",
        },
      },
    },
    "Entity": {
      "List": {
        "1": {
          "description": "Террористические организации",
          "name": "TERRORIST_GROUPS",
        },
        "2": {
          "description": "Нежелательные иностранные или международные организации",
          "name": "UNWANTED_FOREIGN_ORGANIZATIONS",
        },
        "3": {
          "description": "Экстремистские организации (НКО)",
          "name": "NONPROFIT_EXTREMIST_ORGANIZATIONS",
        },
        "4": {
          "description": "Религиозные или общественные организации (экстремистская деятельность)",
          "name": "RELIGIOUS_OR_PUBLIC_EXTREMIST_ACTIVITIES",
        },
        "5": {
          "description": "СМИ иноагенты",
          "name": "MEDIA_FOREIGN_AGENTS",
        },
        "6": {
          "description": "НКО иноагенты",
          "name": "NONPROFIT_FOREIGN_AGENTS",
        },
        "7": {
          "description": "Незарегистрированные иноагенты",
          "name": "UNREGISTERED_FOREIGN_AGENTS",
        },
        "8": {
          "description": "Физические лица иноагенты",
          "name": "PERSON_FOREIGN_AGENTS",
        },
        "9": {
          "description": "Единый реестр иноагентов",
          "name": "UNIVERSAL_REGISTRY_OF_FOREIGN_AGENTS",
        },
      },
      "Type": {
        "1": {
          "description": "Организация",
          "name": "ORGANIZATION",
        },
        "2": {
          "description": "Человек",
          "name": "PERSON",
        },
      },
    },
    "EntityProperty": {
      "Type": {
        "1": {
          "description": "Тикер MOEX",
          "name": "MOEX_TICKER",
        },
        "2": {
          "description": "Тикер SPB",
          "name": "SPB_TICKER",
        },
        "3": {
          "description": "Код ОКПО",
          "name": "OKPO",
        },
      },
    },
    "FlowEvent": {
      "Type": {
        "1": {
          "description": "Удален",
          "name": "DELETED",
        },
      },
    },
    "LeadEvent": {
      "Type": {
        "1": {
          "description": "Прочитан",
          "name": "READ",
        },
        "2": {
          "description": "Создан инфоповод",
          "name": "NEWSBREAK_CREATED",
        },
      },
    },
    "News": {
      "Language": {
        "1": {
          "description": "Русский",
          "name": "RU",
        },
        "2": {
          "description": "Английский",
          "name": "EN",
        },
      },
      "Origin": {
        "1": {
          "description": "АСОИ",
          "name": "ASOI",
        },
        "2": {
          "description": "Автоновости",
          "name": "AUTONEWS",
        },
      },
      "Status": {
        "1": {
          "description": "Черновик",
          "name": "DRAFT",
        },
        "2": {
          "description": "Ожидает взятия в работу",
          "name": "WAITS_FOR_EDIT",
        },
        "3": {
          "description": "В работе",
          "name": "ON_EDIT",
        },
        "7": {
          "description": "Отвал",
          "name": "DISPOSED",
        },
        "8": {
          "description": "Ожидает опубликования",
          "name": "WAITS_FOR_PUBLICATION",
        },
        "10": {
          "description": "Опубликована",
          "name": "PUBLISHED",
        },
      },
      "Type": {
        "1": {
          "description": "Молния",
          "name": "FLASH",
        },
        "2": {
          "description": "Экспресс",
          "name": "EXPRESS",
        },
        "3": {
          "description": "Новость",
          "name": "NEWS",
        },
        "4": {
          "description": "Выпуск",
          "name": "ISSUE",
        },
        "5": {
          "description": "Анонс",
          "name": "ANNOUNCEMENT",
        },
        "6": {
          "description": "Пресс-релиз",
          "name": "PRESS_RELEASE",
        },
        "7": {
          "description": "Сводка событий",
          "name": "SUMMARY",
        },
        "8": {
          "description": "Обзор",
          "name": "REVIEW",
        },
        "9": {
          "description": "Комментарий",
          "name": "COMMENT",
        },
        // TODO: раскомментировать при выполнении задачи 4005
        // "10": {
        //   "description": "Служебное сообщение",
        //   "name": "SERVICE_MESSAGE",
        // }
      },
    },
    "NewsEvent": {
      "Type": {
        "1": {
          "description": "Создана",
          "name": "CREATED",
        },
        "2": {
          "description": "Отправлена",
          "name": "SENT_FOR_EDIT",
        },
        "3": {
          "description": "Взята в работу",
          "name": "PICKED_FOR_EDIT",
        },
        "7": {
          "description": "Отправлена в отвал",
          "name": "DISPOSED",
        },
        "8": {
          "description": "Запланирована",
          "name": "SCHEDULED",
        },
        "9": {
          "description": "Отменена",
          "name": "CANCELLED",
        },
        "10": {
          "description": "Опубликована",
          "name": "PUBLISHED",
        },
        "11": {
          "description": "Переопубликована",
          "name": "REPUBLISHED",
        },
        "12": {
          "description": "Переопубликована без редактирования",
          "name": "REPUBLISHED_UNEDITED",
        },
        "13": {
          "description": "Аннулирована",
          "name": "REVOKED",
        },
        "14": {
          "description": "Расширена",
          "name": "EXTENDED",
        },
        "15": {
          "description": "Переведена",
          "name": "TRANSLATED",
        },
        "16": {
          "description": "Отозвана",
          "name": "RECALLED",
        },
        "100": {
          "description": "Прочитана",
          "name": "READ",
        },
        "200": {
          "description": "Изменена",
          "name": "UPDATED",
        },
        "201": {
          "description": "Удалена",
          "name": "DELETED",
        },
        "202": {
          "description": "Перепланирована",
          "name": "RESCHEDULED",
        },
        "300": {
          "description": "Добавлены авторы",
          "name": "AUTHORS_ADDED",
        },
        "301": {
          "description": "Удалены авторы",
          "name": "AUTHORS_DELETED",
        },
        "400": {
          "description": "Добавлены коды",
          "name": "CODES_ADDED",
        },
        "401": {
          "description": "Удалены коды",
          "name": "CODES_DELETED",
        },
        "402": {
          "description": "Изменены коды направлений публикации",
          "name": "ROUTE_CODES_UPDATED",
        },
        "403": {
          "description": "Коды проставлены автоматически",
          "name": "CODES_AUTO_ASSIGNED",
        },
        "500": {
          "description": "Отправлена на ящик АСОИ",
          "name": "SENT_TO_ASOI",
        },
        "501": {
          "description": "Продублирована в АСОИ",
          "name": "DUPLICATED_IN_ASOI",
        },
        "600": {
          "description": "Добавлен комментарий",
          "name": "COMMENT_ADDED",
        },
        "601": {
          "description": "Прочитан комментарий",
          "name": "COMMENT_READ",
        },
        "700": {
          "description": "Добавлены связи",
          "name": "LINKS_ADDED",
        },
        "701": {
          "description": "Удалены связи",
          "name": "LINKS_DELETED",
        },
      },
    },
    "NewsFTS": {
      "Target": {
        "1": {
          "description": "Заголовок и подзаголовок",
          "name": "HEADLINE_SUBHEADLINE",
        },
        "2": {
          "description": "Текст",
          "name": "BODY",
        },
        "3": {
          "description": "Слаглайн, трешлайн и дейтлайн",
          "name": "SLUGLINE_TRASHLINE_DATELINE",
        },
      },
    },
    "NewsLink": {
      "Type": {
        "1": {
          "description": "Переопубликование",
          "name": "REPUBLICATION",
        },
        "2": {
          "description": "Переопубликование без редактирования",
          "name": "REPUBLICATION_UNEDITED",
        },
        "3": {
          "description": "Аннулирование",
          "name": "REVOCATION",
        },
        "4": {
          "description": "Расширение",
          "name": "EXTENSION",
        },
        "5": {
          "description": "Перевод",
          "name": "TRANSLATION",
        },
        "6": {
          "description": "Копия",
          "name": "COPY",
        },
      },
    },
    "NewsParentRel": {
      "Type": {
        "1": {
          "description": "Переопубликование",
          "name": "REPUBLICATION",
        },
        "2": {
          "description": "Переопубликование без редактирования",
          "name": "REPUBLICATION_UNEDITED",
        },
        "3": {
          "description": "Аннулирование",
          "name": "REVOCATION",
        },
        "4": {
          "description": "Расширение",
          "name": "EXTENSION",
        },
        "5": {
          "description": "Перевод",
          "name": "TRANSLATION",
        },
        "6": {
          "description": "Копия",
          "name": "COPY",
        },
      },
    },
    "Newsbreak": {
      "Status": {
        "0": {
          "description": "Не задан",
          "name": "NOT_SET",
        },
        "1": {
          "description": "Передан в работу",
          "name": "FORWARDED",
        },
        "2": {
          "description": "Взят в работу",
          "name": "PICKED",
        },
        "3": {
          "description": "Отклонен",
          "name": "REJECTED",
        },
      },
    },
    "NewsbreakEvent": {
      "Type": {
        "1": {
          "description": "Прочитан",
          "name": "READ",
        },
        "2": {
          "description": "Передан в работу",
          "name": "FORWARDED",
        },
        "3": {
          "description": "Взят в работу",
          "name": "PICKED",
        },
        "4": {
          "description": "Отклонен",
          "name": "REJECTED",
        },
      },
    },
    "Notification": {
      "Type": {
        "100": {
          "description": "Новые комментарии к новостям",
          "name": "NEWS_COMMENTS",
        },
        "101": {
          "description": "Получение новости в работу",
          "name": "NEWS_FOR_WORK",
        },
        "104": {
          "description": "Взятие новости в работу",
          "name": "NEWS_ON_WORK",
        },
        "106": {
          "description": "Отправка новости в отвал",
          "name": "NEWS_DISPOSE",
        },
        "107": {
          "description": "Опубликование новости",
          "name": "NEWS_PUBLISH",
        },
        "200": {
          "description": "Заметки из АСОИ",
          "name": "ASOI_NOTES",
        },
      },
    },
    "Profile": {
      "Type": {
        "1": {
          "description": "Для написания новости или периодического издания",
          "name": "NEWS_OR_ISSUE",
        },
        "2": {
          "description": "Для опубликования новости",
          "name": "PUBLISH_NEWS",
        },
        "3": {
          "description": "Для опубликования периодического издания",
          "name": "PUBLISH_ISSUE",
        },
        "4": {
          "description": "По умолчанию",
          "name": "DEFAULT",
        },
      },
    },
    "Source": {
      "Type": {
        "0": {
          "description": "Директория",
          "name": "DIRECTORY",
        },
        "1": {
          "description": "Web",
          "name": "WEB",
        },
        "2": {
          "description": "RSS",
          "name": "RSS",
        },
        "100": {
          "description": "ТАСС",
          "name": "TASS",
        },
        "101": {
          "description": "РИА",
          "name": "RIA",
        },
        "102": {
          "description": "BNS",
          "name": "BNS",
        },
      },
    },
    "SourceLog": {
      "Type": {
        "1": {
          "description": "Таймаут получения данных",
          "name": "CRAWL_TIMEOUT",
        },
        "2": {
          "description": "Ошибка получения данных",
          "name": "CRAWL_ERROR",
        },
        "3": {
          "description": "Некорректный статус ответа",
          "name": "CRAWL_INVALID_STATUS",
        },
        "4": {
          "description": "Большой размер данных",
          "name": "CRAWL_BIG_CONTENT_LENGTH",
        },
        "5": {
          "description": "Превышен допустимый размер данных",
          "name": "CRAWL_MAX_CONTENT_SIZE",
        },
        "6": {
          "description": "Переключение на прокси-сервер",
          "name": "CRAWL_SWITCH_TO_PROXY",
        },
        "7": {
          "description": "Переключение с прокси-сервера",
          "name": "CRAWL_SWITCH_FROM_PROXY",
        },
        "8": {
          "description": "Нет данных",
          "name": "CRAWL_NO_CONTENT",
        },
        "100": {
          "description": "Ошибка разбора данных",
          "name": "PROCESS_PARSE_ERROR",
        },
        "101": {
          "description": "Некорректный XPath",
          "name": "PROCESS_INVALID_XPATH",
        },
        "102": {
          "description": "XPath не найден",
          "name": "PROCESS_XPATH_NOT_FOUND",
        },
        "103": {
          "description": "Записи в RSS-ленте не найдены",
          "name": "PROCESS_NO_FEED_ENTRIES",
        },
      },
    },
  } as const satisfies {
    ASOINoteEvent: {
      Type: {
        [key in PsApiV2.ASOINoteEventType]: ReferenceValue
      }
    }
    Entity: {
      List: {
        [key in PsApiV2.EntityList]: ReferenceValue
      }
      Type: {
        [key in PsApiV2.EntityType]: ReferenceValue
      }
    }
    EntityProperty: {
      Type: {
        [key in EntityPropertyType]: ReferenceValue
      }
    }
    FlowEvent: {
      Type: {
        [key in FlowEventType]: ReferenceValue
      }
    }
    LeadEvent: {
      Type: {
        [key in PsApiV2.LeadEventType]: ReferenceValue
      }
    }
    News: {
      Language: {
        [key in PsApiV2.NewsVersionLanguage]: ReferenceValue
      }
      Origin: {
        [key in PsApiV2.NewsOrigin]: ReferenceValue
      }
      Status: {
        [key in PsApiV2.NewsVersionStatus]: ReferenceValue
      }
      Type: {
        [key in PsApiV2.NewsVersionType]: ReferenceValue
      }
    }
    NewsEvent: {
      Type: {
        [key in PsApiV2.NewsVersionEventType]: ReferenceValue
      }
    }
    NewsFTS: {
      Target: {
        [key in NewsFTSTargetType]: ReferenceValue
      }
    }
    NewsLink: {
      Type: {
        [key in PsApiV2.NewsLinkType]: ReferenceValue
      }
    }
    NewsParentRel: {
      Type: {
        [key in PsApiV2.NewsParentRelType]: ReferenceValue
      }
    }
    Newsbreak: {
      Status: {
        [key in PsApiV2.NewsbreakStatus]: ReferenceValue
      }
    }
    NewsbreakEvent: {
      Type: {
        [key in PsApiV2.NewsbreakEventType]: ReferenceValue
      }
    }
    Notification: {
      Type: {
        [key in PsApiV2.NotificationType]: ReferenceValue
      }
    }
    Profile: {
      Type: {
        [key in PsApiV2.ProfileType]: ReferenceValue
      }
    }
    Source: {
      Type: {
        [key in PsApiV2.SourceType]: ReferenceValue
      }
    }
    SourceLog: {
      Type: {
        [key in PsApiV2.SourceLogType]: ReferenceValue
      }
    }
  })
