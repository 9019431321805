<script setup lang="ts">
import { useVirtualizer } from '@tanstack/vue-virtual'
import { computed, useTemplateRef, watchEffect } from 'vue'


const props = withDefaults(
  defineProps<{
    count: number,
    hasMoreItems?: boolean,
    overscan?: number,
    estimateSize?: number,
  }>(),
  {
    hasMoreItems: false,
    overscan: 10,
    estimateSize: 100,
  }
)

const emit = defineEmits<{
  (e: 'loadMore'): void,
}>()

const root = useTemplateRef<HTMLDivElement>('root')

const virtualizer = useVirtualizer(computed(() => ({
  count: props.count,
  overscan: props.overscan,
  getScrollElement: () => root.value,
  estimateSize: () => props.estimateSize,
  isScrollingResetDelay: 80,
  enable: true,
})))

const virtualRows = computed(() => virtualizer.value.getVirtualItems())

const totalSize = computed(() => virtualizer.value.getTotalSize())

const containerStyle = computed(() => ({
  height: `${totalSize.value}px`,
  pointerEvents: virtualizer.value.isScrolling ? 'none' : 'auto',
}))

const itemsStyle = computed(() => ({
  transform: `translateY(${virtualRows.value[0]?.start ?? 0}px)`,
}))

watchEffect(() => {
  const lastItem = virtualRows.value?.at(-1)

  if (
    lastItem
    && (lastItem.index ?? 0) >= (props.count - 1)
    && props.hasMoreItems
  ) {
    emit('loadMore')
  }
})

const measureElement = (el) => {
  // setTimeout fixes virtualizer's issue
  // https://github.com/TanStack/virtual/issues/659?ysclid=m5w8hpk1m2509176583
  setTimeout(() => {
    virtualizer.value.measureElement(el)
  })
}

defineExpose(virtualizer.value) // expose all methods
</script>

<template>
  <div
    ref="root"
    class="virtual-list"
  >
    <slot name="before" />

    <div
      class="virtual-list__container"
      :style="containerStyle"
    >
      <div
        class="virtual-list__items"
        :style="itemsStyle"
      >
        <div
          v-for="virtualItem in virtualRows"
          :ref="measureElement"
          :key="virtualItem.index"
          :data-index="virtualItem.index"
        >
          <slot
            name="item"
            v-bind="{ index: virtualItem.index }"
          />
        </div>
      </div>
    </div>

    <slot name="after" />
  </div>
</template>

<style scoped>
.virtual-list {
  height: 100%;
  overflow: auto;
  contain: strict;
  will-change: scroll-position;
}

.virtual-list__container {
  position: relative
}

.virtual-list__items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
