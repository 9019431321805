<script setup lang="ts">
import CalendarButton from "./buttons/Calendar.vue"
import ColorsButton from "./buttons/Colors.vue"
import ModeSwitcher from "./buttons/ModeSwitcher.vue"
import RepublicationsButton from "./buttons/Republications.vue"
import PanelToolbar from "@/ContextTab/components/Toolbar/PanelToolbar.vue"
import PsButtonToolbar from "@/ContextTab/components/UI/Button/PsButtonToolbar.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import ColorIndicatorModal from "@/ContextTab/modules/news/published/components/colorIndication/ColorIndicatorModal.vue"
import CalendarModal from "@/ContextTab/modules/news/published/components/dateTime/CalendarModal.vue"
import format from "date-fns/format"
import { ru } from "date-fns/locale"
import parseISO from "date-fns/parseISO"
import { computed, ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { PublishedLocalFiltersStore } from "@/ContextApp/stores/news/published/localFilters"
import type { SizeBreakpoints } from '@/ContextTab/composables/types'

const emit = defineEmits<{
  (event: "toggleSearch"): void,
}>()

const store = useStore<PublishedLocalFiltersStore>("publishedLocalFilters")

const colorsModal = ref(false)
const calendarModal = ref(false)

const datesSelected = computed(
  () => store.data.dates?.start && store.data.dates?.end
)

const dateFormat = "dd.MM.yyyy"

const datesLabel = computed(() => {
  if (!datesSelected.value) return "Дата и время"

  let formattedDates = ""

  const start = store.data.dates?.start || null
  const end = store.data.dates?.end || null

  if (start) {
    formattedDates = format(parseISO(start), dateFormat, { locale: ru })
  }

  if (start && end) {
    formattedDates += " – " + format(parseISO(end), dateFormat, { locale: ru })
  }

  return formattedDates
})

const repFilter = computed(() =>
  store.data.hideRepublications ? "included" : "unchecked"
)

const toolbarBreakpoints: SizeBreakpoints = {
  "xs": 0,
  "sm": 320,
  "md": 380,
  "lg": 420,
  "xl": 520,
  "xxl": 1300,
  "xxxl": 1800,
}

const toggleRepublication = () => store.toggleRepublications?.()

const clearDates = () => store.clearDates?.()
</script>

<template>
  <PanelToolbar
    class="published-titlebar"
    background="#0069e4"
    :breakpoints="toolbarBreakpoints"
  >
    <template #left>
      <PsButtonToolbar
        :circle="true"
        :border="false"
        @click="emit('toggleSearch')"
      >
        <PsIcon
          type="font"
          name="search"
          size="20"
          color="#fff"
        />
      </PsButtonToolbar>

      <CalendarButton
        :datesLabel="datesLabel"
        :datesSelected="!!datesSelected"
        @click="calendarModal = !calendarModal"
        @clear="clearDates"
      />
      <RepublicationsButton
        :checked="repFilter"
        @click="toggleRepublication"
      />
      <ColorsButton @click="colorsModal = true" />
      <ColorIndicatorModal v-model="colorsModal" />
      <Suspense>
        <CalendarModal v-model="calendarModal" />
      </Suspense>
    </template>
    <template #right>
      <ModeSwitcher />
    </template>
  </PanelToolbar>
</template>

<style scoped lang="postcss">
.published-titlebar {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
</style>
