<script setup lang="ts">
import CircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { computed } from "vue"
import { REFERENCES } from "@/lib/references"
import { NEWS_TYPES_BY_NAME } from '@/lib/referencesByName'

const props = withDefaults(
  defineProps<{
    typeId?: number
    alertsCount?: number
    customIconName?: string
    loading?: boolean
    iconSize?: number
  }>(),
  {
    typeId: undefined,
    alertsCount: 0,
    customIconName: undefined,
    loading: false,
    iconSize: 18
  }
)

const emit = defineEmits<{
  (event: "click"): void
}>()

const iconName = computed(
  () => props.customIconName || (props.typeId ? "newsType/newstype-" + REFERENCES.News.Type[props.typeId].name.toLowerCase() : "")
)

const alertsCountStr = computed(() =>
  props.alertsCount > 99 ? "99+" : props.alertsCount
)
const isAlertOfUrgent = computed(() => props.typeId === NEWS_TYPES_BY_NAME["FLASH"].id
  || props.typeId === NEWS_TYPES_BY_NAME["EXPRESS"].id
)
</script>

<template>
  <button
    class="tasks-panel-button"
    type="button"
    :class="{ highlighted: !!alertsCount, urgent: isAlertOfUrgent }"
    @click="emit('click')"
  >
    <PsIcon :name="iconName" :size="iconSize" />
    <CircularLoader v-if="loading" class="tasks-panel-button__loader" />
    <span v-else class="tasks-panel-button__alerts-count">{{
      alertsCountStr
    }}</span>
  </button>
</template>

<style scoped lang="postcss">
.tasks-panel-button {
  display: inline-flex;
  justify-content: space-between;
  align-items: self-end;
  white-space: nowrap;
  font-weight: 700;
  padding: 7px 5px 7px 4px;
  width: 42px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.6;
  background-color: var(--color-white);
  transition: 0.3s filter ease;

  &.highlighted {
    background-color: #fff6e6;
    opacity: 1;

    &.urgent {
      color: var(--color-danger-shade-150);
    }
  }

  &:hover {
    filter: brightness(90%);
  }
}
.tasks-panel-button__alerts-count {
  line-height: 1;
}
.tasks-panel-button__loader {
  --loader--sm--size: 16px;
}
</style>
