<script lang="ts" setup>
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { debounce as $debounce } from "lodash-es"
import { computed, ref } from "vue"
import { NEWS_TYPES_BY_NAME } from "@/lib/referencesByName"
import { REFERENCES } from "@/lib/references"

const props = withDefaults(
  defineProps<{
    typeId: number
    disabled?: boolean
    active?: boolean
    showLabel?: boolean
    iconSize?: number
    color?: string
  }>(),
  {
    disabled: false,
    active: false,
    showLabel: false,
    iconSize: 20,
    color: undefined,
  }
)

const emit = defineEmits<{
  (event: "click"): void
  (event: "dblclick"): void
}>()

const delayedClick = ref<null | (() => void)>(null)
const clickedOnce = ref(false)

const iconName = computed(() => "newsType/newstype-" + REFERENCES.News.Type[props.typeId]?.name.toLowerCase() || "")

const className = computed(() => {
  if (props.color) {
    return ""
  }
  if (props.typeId === NEWS_TYPES_BY_NAME["FLASH"].id || props.typeId === NEWS_TYPES_BY_NAME["EXPRESS"].id) {
    return "_text-color:primary-shade-150"
  } else {
    return "_text-color:inherit"
  }
})

const label = computed(
  () => REFERENCES.News.Type[props.typeId]?.description || ""
)

function clickHandler() {
  if (!delayedClick.value) {
    delayedClick.value = $debounce(doClick, 2000)
  }

  if (clickedOnce.value) {
    delayedClick.value.cancel()
    clickedOnce.value = false
  } else {
    delayedClick.value()
    clickedOnce.value = true
  }
}

function doClick() {
  emit("click")
  clickedOnce.value = false
}

function doubleClickHandler() {
  emit("dblclick")
}
</script>

<template>
  <PsButton
    :disabled="disabled"
    :padding="false"
    :border="false"
    :class="className"
    @click="clickHandler"
    @dblclick.stop.prevent="doubleClickHandler"
  >
    <slot name="icon">
      <PsIcon
        v-if="iconName"
        :name="iconName"
        :color="color"
        :size="iconSize"
        data-test-selector="news-item-type"
      />
    </slot>
    <template v-if="showLabel">
      {{ label }}
    </template>
  </PsButton>
</template>

<style lang="postcss" scoped>
.ps-button {
  &.button.-disabled,
  &.button:disabled {
    cursor: default;
  }
}
</style>
