<script setup lang="ts">
import { onMounted, nextTick, onUnmounted, watch } from "vue"
// eslint-disable-next-line import/no-extraneous-dependencies
import createLegacyApp from "@/LegacyApp/dist/publishing-ui.mjs"
// eslint-disable-next-line import/no-extraneous-dependencies
import "@/LegacyApp/dist/style.css"
import usePassAuth from "./composables/usePassAuth"
import usePassCodes from "./composables/usePassCodes"
import usePassContextId from "./composables/usePassContextId"
import usePassProfile from "./composables/usePassProfile"
import usePassRefs from "./composables/usePassRefs"
import usePassUiState from "./composables/usePassUiState"
import usePassUsers from "./composables/usePassUsers"
import { useScreenBreakpoints } from "@/ContextTab/composables/useScreenBreakpoints"

const props = withDefaults(
  defineProps<{
    isTaskPanelShowed?: boolean
  }>(),
  {
    isTaskPanelShowed: false,
  }
)

let legacyApp: any = null

const { isScreenSmall } = useScreenBreakpoints()

const passAuth = await usePassAuth()
const passProfile = await usePassProfile()
const passRefs = await usePassRefs()
const passCodes = await usePassCodes()
const passUsers = await usePassUsers()
const passContextId = await usePassContextId()
const passUiState = usePassUiState()

async function initLegacy() {
  legacyApp = createLegacyApp()

  await passAuth(legacyApp)
  await passRefs(legacyApp)
  await passProfile(legacyApp)
  await passCodes(legacyApp)
  await passUsers(legacyApp)
  await passContextId(legacyApp)

  await nextTick()

  legacyApp.$mount("#legacy-app-wrapper")
}

onUnmounted(async () => {
  if (legacyApp !== null) {
    legacyApp.$store.commit("auth/UNMOUNT")
    await legacyApp.$store.dispatch("auth/reset")
    await legacyApp.$store.dispatch("userProfile/clearProfile")
    legacyApp.$destroy()
    legacyApp = null
  }
})

onMounted(() => {
  initLegacy()
})

watch(
  () => props.isTaskPanelShowed,
  (next) => passUiState(legacyApp, next)
)
</script>

<template>
  <div
    id="legacy-app"
    :class="{
      'has-aside': isTaskPanelShowed && !isScreenSmall, // task panel shown, but not visible
      sm: isScreenSmall,
    }"
  >
    <div id="legacy-app-wrapper" />
  </div>
</template>

<style scoped lang="postcss">
#legacy-app {
  &.sm {
    width: 100vw;
  }
}
</style>
