import { onBeforeUnmount, toValue, type MaybeRef } from 'vue'
import { useThrottleFn } from '@vueuse/core'


const THROTTLE_INTERVAL = 150

export const useKeyboardNav = (
  itemsCount: MaybeRef<number>,
  currentIndex: MaybeRef<number>,
  callback: (index: number) => void
) => {
  const abortController = new AbortController()

  const keys = {
    ArrowUp: -1,
    ArrowDown: 1,
  }

  const onKeyDown = (e: KeyboardEvent) => {
    const direction = keys[e.key]

    if (!direction) {
      return
    }

    e.preventDefault()

    const total = toValue(itemsCount) ?? 0
    let index = (toValue(currentIndex) ?? 0) + direction
    index = Math.max(index >= total ? total - 1 : index, 0)

    callback(index)
  }

  const throttledKeyDown = useThrottleFn(onKeyDown, THROTTLE_INTERVAL)

  document.addEventListener('keydown', throttledKeyDown, { signal: abortController.signal })

  onBeforeUnmount(() => {
    abortController.abort()
  })
}
