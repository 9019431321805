import type { GatewaySubscriptionHubMessage } from '@/ContextApp/middleware/gateway'


type GatewayMessageToLegacy = {
  payload: GatewaySubscriptionHubMessage["payload"],
  fromContext: GatewaySubscriptionHubMessage["meta"]["fromContext"]
} & Pick<GatewaySubscriptionHubMessage["meta"], "subKey" | "subType" | "typeId">

class LegacyBridge {
  private _channel: BroadcastChannel
  private _listeners: Record<string, any> = {}
  private _onMessage: ((message: any) => Promise<void>) | null = null
  private _timers: Record<string, any> = {}

  constructor() {
    this._channel = new BroadcastChannel("ps-bridge")

    this._channel.addEventListener("message", (message) => {
      if (message.data.source === "publishing-ui" || message.data.source === "admin") {
        if (message.data.uid) {
          if (this._listeners[message.data.uid]) {
            this._listeners[message.data.uid]()
          }
        } else if (this._onMessage) {
          this._onMessage(message)
        }
      }
    })

    this.onMessage = this.onMessage.bind(this)
    this.openNewsItem = this.openNewsItem.bind(this)
    this.updateToken = this.updateToken.bind(this)
  }

  onMessage(listener: (message: any) => Promise<void>) {
    this._onMessage = listener
  }

  public openNewsItem(id: string | number) {
    return new Promise((resolve) => {
      const uid = globalThis.crypto.randomUUID()

      this._listeners[uid] = () => {
        clearTimeout(this._timers[uid])
        delete this._listeners[uid]
        resolve(true)
      }

      this._timers[uid] = setTimeout(() => {
        resolve(false)
      }, 1000)

      this._channel.postMessage({
        source: "ps-ui",
        uid,
        action: "openNewsItem",
        contentType: "news",
        id,
        timestamp: Date.now(),
      })
    })
  }

  public updateToken(accessToken: string, refreshToken: string) {
    this._channel.postMessage({
      action: "updateToken",
      source: "ps-ui",
      accessToken,
      refreshToken,
      timestamp: Date.now(),
    })
  }

  public gatewayMessage(message: GatewayMessageToLegacy) {
    this._channel.postMessage({
      action: "gateway",
      source: "ps-ui",
      message,
      timestamp: Date.now(),
    })
  }

  public sendNews(contextId: string | null, payload: any) {
    this._channel.postMessage({
      source: "ps-ui",
      action: "sendNews",
      contextId,
      payload,
      timestamp: Date.now(),
    })
  }

  public sendToAsoi(contextId: string | null, payload: any) {
    this._channel.postMessage({
      source: "ps-ui",
      action: "sendToAsoi",
      contextId,
      payload,
      timestamp: Date.now(),
    })
  }

  public hideShareModal(contextId: string | null) {
    this._channel.postMessage({
      source: "ps-ui",
      action: "hideShareModal",
      contextId,
      timestamp: Date.now(),
    })
  }

  public openCanvas() {
    this._channel.postMessage({
      source: "ps-ui",
      action: "openCanvas",
      timestamp: Date.now(),
    })
  }
}

export default new LegacyBridge()
