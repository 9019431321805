<script setup lang="ts">
import ItemCodesPanel from "./ItemCodesPanel.vue"
import PreviewPanel from "./preview/PreviewPanel.vue"
import PublishedPanel from "./PublishedPanel.vue"
import PanelGrid from "@/ContextTab/layout/grid/PanelGrid.vue"
import { refThrottled, useThrottleFn } from "@vueuse/core"
import endOfToday from "date-fns/endOfToday"
import format from "date-fns/format"
import startOfToday from "date-fns/startOfToday"
import { ref, computed, watch, onBeforeUnmount, useTemplateRef } from "vue"
import useProfile from "@/ContextTab/modules/user/composables/useProfile"
import globalTimer from "@/ContextTab/services/globalTimer"
import { useStoreAsync } from "@/ContextTab/useStore"
import { base64ToUtf, utfToBase64 } from "@/utils/base64"
import { filterPrivate } from "@/utils/filters"
import type { NewsItemPublished } from "./types"
import type { NewsPublishedPreset, NewsSetFilters } from '@/lib/types'
import type { PublishedNewsStore } from "@/ContextApp/stores/news/published/set"
import type { PublishedFiltersStore } from "@/ContextApp/stores/news/published/filters"
import type { PublishedPresetsStore } from "@/ContextApp/stores/news/published/presets"
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'

const presetStore = await useStoreAsync<PublishedPresetsStore>("publishedPresets")
const filterStore = await useStoreAsync<PublishedFiltersStore>("publishedFilters")


if (window.location.hash) {
  try {
    const base64 = window.location.hash.slice(1, window.location.hash.length)
    const decoded = base64ToUtf(base64)
    const data = JSON.parse(decoded)
    if (data) {
      await presetStore.fetchPresets()
      await applyPreset(data.id)
    }
  } catch (err) {
    console.warn("Не удалось раскодировать пресет из адресной строки", err)
  }
}

const publishedLocalFilters = await useStoreAsync("publishedLocalFilters")
const store = await useStoreAsync<PublishedNewsStore>("publishedNews")
store.loadNext()

const { isScreenSmall } = useScreenBreakpoints()
const isFluidLayout = computed(() => !isScreenSmall.value)

const publishedPanel = useTemplateRef('publishedPanel')

const items = computed(() => store.data.newsList)
const countUnreadNews = computed(() => store.data.lockedHeadSet?.length)

const { publishedPanelMode, profile } = useProfile({})

const filters = computed(() => filterPrivate(filterStore.data.filters))

const selectedId = ref(1)
const selectedIdThrottled = refThrottled(selectedId, 200)

const rightSupplyShowed = ref(false)
const treePanelOpened = ref(true)

const selectedItem = computed(() =>
  items.value?.find((item: any) => item?.id === selectedIdThrottled.value)
)

const selectItem = (item: NewsItemPublished) => {
  if (item.id != null) {
    selectedId.value = item.id
  }
  rightSupplyShowed.value = true

  if (item.id === items.value?.[0]?.id) {
    if (countUnreadNews.value === 0) {
      store.unlockHead()
    }
  } else {
    store.lockHead()
  }
}

const errors = computed(() => {
  const errors = []
  if (store.data.error) {
    errors.push(store.data.error)
  }
  return errors.length ? errors : null
})

const defaultDates = ref({
  start: format(startOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
  end: format(endOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
})

const dates = ref({
  start: defaultDates.value.start,
  end: defaultDates.value.end,
})

const updateDates = async (range: any) => {
  dates.value.start = range.start || undefined
  dates.value.end = range.end || undefined
  publishedLocalFilters.updateDates({
    fromDt: dates.value.start,
    toDt: dates.value.end,
  })
  store.reload()
}

const checkDates = () => {
  const pickerDatesChanged =
    defaultDates.value.start !== dates.value.start ||
    defaultDates.value.end !== dates.value.end
  if (pickerDatesChanged) {
    return
  }

  const defaultDatesChanged =
    defaultDates.value.start !==
      format(startOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX") ||
    defaultDates.value.end !== format(endOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX")

  if (defaultDatesChanged) {
    defaultDates.value = {
      start: format(startOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
      end: format(endOfToday(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
    }

    updateDates({
      start: defaultDates.value.start,
      end: defaultDates.value.end,
    })
  }
}
globalTimer.registerListener(checkDates)

watch(items, (next, prev) => {
  if (next[0] && next[0].id !== prev?.[0]?.id) {
    if (store.data.isLockedHead === false) {
      selectItem(next[0])
    }
  }
})

const onReachedEnd = useThrottleFn(
  (visible: boolean) => {
    if (
      visible &&
      items.value?.length &&
      !store.data.error &&
      !store.fetching.value &&
      store.data.hasMoreItems
    ) {
      store.loadNext()
    }
  },
  500,
  true
)

const onReachedStart = (isVisible: boolean) => {
  if (isVisible) {
    store.unlockHead()
    if (items.value?.length > 100) {
      store.slice()
    }
  } else {
    store.lockHead()
  }
}

function showNew() {
  if (publishedPanel.value?.getScrollTopSize()) {
    publishedPanel.value?.scrollToTop()
  } else {
    store.unlockHead()
  }
}

const onOpenActionPopover = () => {
  store.lockHead()
}

const preset = computed<NewsPublishedPreset | undefined>(() =>
  presetStore.data.presets?.find(
    preset => preset?.id === presetStore.data.currentPresetId
  )
)

const savePreset = async (
  filters: Partial<NewsSetFilters>,
  name: string | null = null
) => {
  if (preset.value) {
    await presetStore.updatePreset(preset.value.id, {
      ...filters,
      name: name || undefined,
    })
  }
}

async function updateFilters(filters: Partial<NewsSetFilters>) {
  filterStore.updateFilters(filters)
}

async function resetFilters() {
  filterStore.resetFilters()
  window.location.hash = ""
}

async function clearFilter(name: "codes" | "feeds" | "users") {
  filterStore.clearFilter(name)
}

async function applyPreset(id: number) {
  const applyResult = await presetStore.applyPreset(id)
  window.location.hash = utfToBase64(
    JSON.stringify({
      id: applyResult.payload.id,
      name: applyResult.payload.name,
    })
  )
  await updateFilters(applyResult.payload)
}

async function clearPreset() {
  await presetStore.clearPreset()
  window.location.hash = ""
  resetFilters()
}

const searchMode = computed(() => store.data.searchMode ?? false)
const showSearch = computed(() => store.data.showSearch ?? false)
const updateShowSearch = (show: boolean) => store.updateShowSearch(show)

async function search() {
  if (!searchMode.value) {
    store.switchMode()
  } else {
    store.search()
  }
}

function clearSearch() {
  if (searchMode.value) {
    store.switchMode()
    publishedPanel.value?.scrollToTop()
  }
}

onBeforeUnmount(() => {
  globalTimer.unregisterListener(checkDates)
})
</script>

<template>
  <PanelGrid
    v-if="profile"
    class="ps-published-workspace"
    :gridType="publishedPanelMode"
    :isFluidLayout="isFluidLayout"
  >
    <template #left>
      <ItemCodesPanel
        v-if="selectedItem"
        :item="selectedItem"
      />
      <div
        v-else
        class="preview-fallback"
      >
        Новость не выбрана
      </div>
    </template>
    <template #main>
      <PublishedPanel
        ref="publishedPanel"
        :countUnreadNews="countUnreadNews"
        :dates="dates"
        :errors="errors"
        :filters="filters"
        :hasMoreItems="store.data.hasMoreItems"
        :isFetching="store.data.isFetching"
        :items="items"
        :mode="publishedPanelMode"
        :selectedItemId="selectedId"
        :preset="preset"
        :searchMode="searchMode"
        :showSearch="showSearch"
        @clear="clearFilter"
        @clickUnread="showNew"
        @onOpenActionPopover="onOpenActionPopover"
        @reachedEnd="onReachedEnd"
        @reachedStart="onReachedStart"
        @repeat="store.reload()"
        @resetFilters="resetFilters"
        @savePreset="savePreset"
        @select="selectItem"
        @toggleTree="treePanelOpened = !treePanelOpened"
        @updateFilters="updateFilters"
        @applyPreset="applyPreset"
        @clearPreset="clearPreset"
        @search="search"
        @clearSearch="clearSearch"
        @update:showSearch="updateShowSearch"
      />
    </template>
    <template #preview>
      <PreviewPanel
        v-if="selectedItem"
        :item="selectedItem"
        :mode="publishedPanelMode"
        :searchMode="searchMode"
      />
      <div
        v-else
        class="preview-fallback"
      >
        Новость не выбрана
      </div>
    </template>
  </PanelGrid>
</template>

<style lang="postcss" scoped>
.preview-fallback {
  background-color: #f2f2f2;
  color: #777;
  opacity: 0.8;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
