import { unref } from "vue"
import { filterPrivate } from "@/utils/filters"
import { cloneDeep } from 'lodash-es'


const updatableFileds = [
  "colors",
  "panels",
  "default_dateline_agency",
  "default_dateline_city",
  "default_news_type_id",
  "default_recipient_group_id",
  "default_recipient_user_id",
  "filters",
  "fullscreen",
  "newsEditor",
  "publishedPanelMode",
]

export function makeProfilePatch(profile: any) {
  const raw = unref(profile) // in fact, we expect ref here
  const patch = updatableFileds.reduce((acc, field) => {
    acc[field] = raw?.[field] ?? null
    return acc
  }, {})

  return filterPrivate(cloneDeep(patch))
}
