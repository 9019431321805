<script setup lang="ts">
import PbDatePicker from "@/ContextTab/components/Date/DatePicker/DatePicker.vue"
import PsSubmitGroup from "@/ContextTab/components/PsSubmitGroup.vue"
import PsModal from "@/ContextTab/components/UI/PsModal.vue"
import { useVModel } from "@vueuse/core"
import { reactive, ref, watch } from "vue"
import { useStoreAsync } from "@/ContextTab/useStore"
import type { PublishedLocalFiltersStore } from "@/ContextApp/stores/news/published/localFilters"

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (e: "update:modelValue", val: (typeof props)["modelValue"]): void
  (e: "cancel"): void
}>()

interface DatesState {
  localDates: {
    start: string | null
    end: string | null
  }
}
const state = reactive<DatesState>({
  localDates: {
    start: null,
    end: null,
  },
})

const store = await useStoreAsync<PublishedLocalFiltersStore>("publishedLocalFilters")
state.localDates.start = store.data.dates.start
state.localDates.end = store.data.dates.end

const show = useVModel(props, "modelValue", emit)

const datesSubmitDisabled = ref(true)
const toggleSubmitDisabled = (value: boolean) => {
  datesSubmitDisabled.value = value
}

watch(store.data.dates, (next, prev) => {
  if (next.start !== prev.start || next.end !== prev.end) {
    state.localDates.start = store.data.dates.start
    state.localDates.end = store.data.dates.end
  }
})

const cancel = () => {
  show.value = false
  state.localDates.start = store.data.dates?.start
  state.localDates.end = store.data.dates?.end
}

const apply = () => {
  store.updateDates(state.localDates)
  show.value = false
}
</script>

<template>
  <PsModal v-model:show="show" header="Дата и время" size="sm">
    <div class="ps-modal-calendar">
      <!-- FIXME: в PbDatePicker нужно добавить сброс параметров вместо v-if -->
      <PbDatePicker
        v-if="show"
        v-model="state.localDates"
        @toggleSubmitDisabled="toggleSubmitDisabled"
      />
    </div>

    <template #footer>
      <PsSubmitGroup submitLabel="Сохранить" @submit="apply" @cancel="cancel" />
    </template>
  </PsModal>
</template>

<style scoped lang="postcss">
.ps-modal-calendar {
  display: flex;
  justify-content: center;
}
</style>
