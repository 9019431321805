import { useStoreAsync } from "@/ContextTab/useStore"
import deepToRaw from "@/utils/deepToRaw"
import { AuthStatus, type AuthStore } from "@/ContextApp/stores/auth"
import { watch } from 'vue'

export default async function usePassAuth() {
  const store = await useStoreAsync<AuthStore>("auth", { shared: true })

  return async (legacyApp: any) => {
    if (store.data.authorized === AuthStatus.authorized) {
      await legacyApp.$store.dispatch("auth/start", {
        userId: store.data.userId,
        login: store.data.login,
        accessToken: store.data.accessToken,
        refreshToken: store.data.refreshToken,
        permissions: deepToRaw(store.data.tokenData?.permissions ?? {}),
      })
    }

    watch(() => store.data.sessionId, (sessionId) => {
      legacyApp.$store.$sessionId = sessionId // $sessionId, not sessionId
    }, {
      immediate: true
    })
  }
}
