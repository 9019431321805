<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { computed } from "vue"
import type { SizeBreakpointMark } from "@/ContextTab/composables/types"

type Props = {
  counter: number
  isCompact?: boolean
  size?: SizeBreakpointMark
  max?: number
}

const props = withDefaults(defineProps<Props>(), {
  isCompact: false,
  size: "sm",
  max: 999,
})

const emit = defineEmits(["click"])

const curCounter = computed(() =>
  props.counter <= props.max ? props.counter : `${props.max}+`
)
</script>

<template>
  <PsButton
    v-if="counter"
    class="unread-ribbon"
    :size="size"
    color="primary"
    :round="true"
    @click="emit('click')"
  >
    <template #suffix>
      <PsIcon name="arrow-up" size="12" />
    </template>
    <span>
      <template v-if="!isCompact">Есть новые новости:&nbsp;</template>
      <span
        v-if="curCounter" data-test-selector="unread-count"
      >
        {{ curCounter }}
      </span>
    </span>
  </PsButton>
</template>

<style scoped>
.unread-ribbon {
  box-shadow: 0 2px 10px #0003;
  --n-padding: 1em 1.5em 1em 1em !important;
}
</style>
